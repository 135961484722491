/* General App container styling */
.app-container {
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.background {
  background-image: url('./components/backgroundspace.png'); /* Adjust the path if needed */
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100%;
  height: 110vh; /* Makes the background fill the entire viewport */
  position: fixed; /* Keeps the background fixed even if there’s scrolling */
  z-index: -1; /* Places it behind other content */
}
.backgroundlight {
  background-image: url('./components/backgroundlight.jpg'); /* Adjust the path if needed */
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100%;
  height: 110vh; /* Makes the background fill the entire viewport */
  position: fixed; /* Keeps the background fixed even if there’s scrolling */
  z-index: -1; /* Places it behind other content */
}

/* Dark and Light Mode Styling */

.content-container {
  flex-grow: 1;
  padding: 20px;
}

/* Navbar Styling */

.tabs {
  display: flex;
  gap: 20px;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
}

.dark-mode-toggle {
  padding: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

