.projects {
  position: relative;
  max-width: 100vw;
    z-index: 1000; 
    color: #eee;
    margin-bottom: 20px;
    font-family: 'Georgia', serif;
    --light-bg-color: #FFA858;
  --light-txt-color:  #FFF5E1;
  --dark-bg-color: #30343F;
  --dark-txt-color: #E6E8F0;
  }
  
  .project-list {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .project-card.light {
    background: var(--light-txt-color);
    color: var(--light-bg-color);
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    max-width: 300px;
    padding: 20px;
    box-shadow: 3px 4px 10px 10px rgba(79, 76, 76, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .project-card.dark {
    background: var(--dark-txt-color);
    color: var(--dark-bg-color);
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    max-width: 300px;
    padding: 20px;
    box-shadow: 3px 4px 10px 10px rgba(79, 76, 76, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
  }
  