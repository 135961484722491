/* index.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  overflow-x: hidden;
  height: 100vh;
}

h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #7302fd;
}

a:hover {
  color: #fff;
}

section {
  text-align: center;
}
