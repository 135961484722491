/* Base styles for navigation */
.navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    font-family: 'Georgia', serif;
    --light-bg-color: #FFA858;
    --light-txt-color:  #FFF5E1;
    --dark-bg-color: #30343F;
    --dark-txt-color: #E6E8F0;
  }
  
  .navbar {
    display: flex;
    gap: 20px;
    padding: 5px;
    /* background-color: white; */
    border-radius: 50px;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .navbar.light-mode {
    background-color: var(--light-txt-color);
    color: var(--light-bg-color);
  }
  
  .tab {
    width: 55px;
    cursor: pointer;
    border-radius: 25px;
    transition: background-color 0.3s;
  }

  .tab.light-mode {
    background-color: var(--light-txt-color);
    color: var(--light-bg-color);
  }
  
  .tab.active.light-mode {
    background-color: var(--light-bg-color);
    color: var(--light-txt-color);
  }
  
  .tab.active.dark-mode {
    background-color: var(--dark-txt-color);
    color: var(--dark-bg-color);
  }
  .tab.dark-mode:hover {
    background-color: var(--dark-txt-color);
     color: var(--dark-bg-color);
  }

  .tab.light-mode:hover {
    background-color: var(--light-bg-color);
    color: var(--light-txt-color);
  }
  
  .toggle-dark-mode.dark-mode {
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    height: fit-content;
    font-family: 'Georgia', serif;
    background-color: var(--light-txt-color);
    color: var(--light-bg-color);
  }

  .toggle-dark-mode.light-mode {
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    height: fit-content;
    font-family: 'Georgia', serif;
    background-color: var(--dark-bg-color);
     color: var(--dark-txt-color);
  }
  
  /* Content container */
  .tab-content {
    margin-top: 5px; /* Reduce gap between navbar and content */
    padding: 20px;
    width: 80%;
    max-width: 1200px;
    border-radius: 10px;
    transition: height 0.6s ease, opacity 0.6s ease;
    height: 0; /* Initially collapsed */
    opacity: 0; /* Initially hidden */
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  
  .tab-content.open {
    height: 75vh; /* Take up 90% of the viewport height */
    opacity: 1;  /* Make visible */
  }
  
  .light-mode {
    background-color: var(--light-bg-color);
    color: var(--light-txt-color);
  }

  .dark-mode {
    background-color: var(--dark-bg-color);
    color: var(--dark-txt-color);
  }

  .x-mark {
    width: 100%;
  }
  .x-mark button{
    z-index: 90000;
    display: flex;
    padding: 5px 20px;
    font-size: 18px;
    margin-left: auto;
    cursor: pointer;
    background-color: #333;
    color: #FFF;
    border-radius: 20px;
  }
  .toggle-dark-mode-mobile{
    display: none;
    }
  
  @media (max-width: 1100px) {
  .navbar {
    width: 90%;
    padding: 10px;
  }
  
  .toggle-dark-mode{
    display: none;
  }

  .toggle-dark-mode-mobile.dark-mode {
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    height: fit-content;
    font-family: 'Georgia', serif;
    margin-bottom: 10px;
    background-color: #FFF;
    color: black;
  }

  .toggle-dark-mode-mobile.light-mode {
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    height: fit-content;
    font-family: 'Georgia', serif;
    margin-bottom: 10px;
    background-color: black;
    color: white;
  }

  .tabs {
    display: none; /* Hide the tabs on mobile */
    flex-direction: column;
    gap: 15px;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

    .tab-content.open {
    height: 65vh; /* Take up 90% of the viewport height */
    opacity: 1;  /* Make visible */
  }
  
}