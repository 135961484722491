/* Container for both image and content side-by-side */
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  --light-bg-color: #FFA858;
  --light-txt-color:  #FFF5E1;
  --dark-bg-color: #30343F;
  --dark-txt-color: #E6E8F0;
}

/* Styling for the image */
.about-image {
  flex: 1;
}

.about-image img {
  width: 60%;
  height: auto;
  border-radius: 10px;
}

.about-content {
  flex: 1;
  padding: 30px;
  text-align: center;
  border-radius: 20px;
  max-width: 800px;
  margin-bottom: 20px;
}
/* Styling for the content */
.about-content.light { 
  background-color: var(--light-txt-color);
  color: var(--light-bg-color);
}

.about-content.dark {
  background-color: var(--dark-txt-color);
  color: var(--dark-bg-color);
}

.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Georgia', serif;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  font-weight: 400;
}

  
@media (max-width: 1100px) {
  .about-container {
    flex-direction: column-reverse;
    margin-top: 10px;
    text-align: center;
    padding: 5px;
    padding-top: 0px;
  }


  .about-image {
    flex: 1;
    padding-right: 0px;
  }

  .about-image img {
    width: 90%;
    height: auto;
    border-radius: 10px;
  }
}