/* Contact.css */
.contact-page {
  font-family: 'Georgia', serif;
  box-sizing: border-box;
  width: 100%;
  z-index: 1000;
    --light-bg-color: #FFA858;
  --light-txt-color:  #FFF5E1;
  --dark-bg-color: #30343F;
  --dark-txt-color: #E6E8F0;
}

.contact-form-ion {
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  max-width: 1000px;
  padding: 20px 30px;
  margin-top: 10px;
}

.contact-form-ion.light {
  background-color: var(--light-txt-color);
  color: var(--light-bg-color);
}

.contact-form-ion.dark {
  background-color: var(--dark-txt-color);
  color: var(--dark-bg-color);
}

.contact-form-ion h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 15px;
}

.contact-form-ion p {
  text-align: center;
  margin-bottom: 20px;

}


.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  font-size: 1.1em;
}

.form-group input,
.form-group textarea {
  background-color: #eee;
  width: 95%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;

  border-radius: 5px;
  margin-top: 5px;
}

.form-group textarea {
  height: 100px;
}

.cta-button {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 30px;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.cta-button.light {
    background-color: var(--light-txt-color);
    color: var(--light-bg-color);
    border: 1px solid var(--light-bg-color);
  }

.cta-button.dark {
    background-color: var(--dark-txt-color);
    color: var(--dark-bg-color);
    border: 1px solid var(--dark-bg-color);
  }

.cta-button:hover {
  background-color: #0eb7d4; /* Hover effect */
  color: #000;
}

.submission-message {
  margin-top: 30px;
  font-size: 1.2em;
  color: #28a745; /* Green for success message */
}


.footer .social-media a {
  color: #eee;
  text-decoration: none;
  margin: 0 10px;
}

.footer .social-media a:hover {
  text-decoration: underline;
}

/* Mobile Responsiveness (Media Queries) */

/* Tablets and below (max-width: 1024px) */
@media (max-width: 1024px) {
  .hero h1 {
    font-size: 2.5em;
  }

  .hero p {
    font-size: 1.3em;
  }


  .contact-form-ion {
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    max-width: 1000px;
    padding: 20px 5px;
  }

  .contact-form {
    padding: 0px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 1em;
    padding: 12px;
  }
  .form-group {
      width: 90%;
  }
  .cta-button {
    padding: 10px 25px;
    font-size: 1em;
  }
}

/* Small Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.3em;
  }

  .hero p {
    font-size: 1.2em;
  }

  .cta-button {
    padding: 10px 25px;
    font-size: 1em;
  }

  .contact-form {
    max-width: 100%;
  }

  .form-group input,
  .form-group textarea {
    font-size: 1em;
    padding: 12px;
  }

  .submission-message {
    font-size: 1.1em;
  }
}

/* Extra Small Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1.1em;
  }

  .cta-button {
    padding: 8px 20px;
    font-size: 0.9em;
  }

  .form-group input,
  .form-group textarea {
    font-size: 1em;
    padding: 12px;
    margin-left: auto;
    margin-right: auto;

  }

  .footer {
    padding: 10px 0;
    font-size: 0.85em;
  }
}
